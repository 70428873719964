.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App a {
  color: #61dafb;
  text-decoration: none;
}

.main {
  flex-grow: 1;
  padding: 0 10px;
  /* margin: 0 auto; */
}

.blog {
  margin: 0 auto;
  max-width: 600px;
}

.login {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.bloglist {
  margin: 0 auto;
  text-align: center;
}

.bloglist ul {
  list-style: none;
}

.editor {
  margin: 0 auto;
  width: 500px;
}

.editor textarea {
  height: 300px;
}

.running td {
  padding: 5px;
}

.running .title {
  width: 600px;
}

.header {
  flex-shrink: 0;
  padding: 10px;
  font-size: calc(10px + 2vmin);
  border-bottom: 3px solid #ccc;
  margin-bottom: 1em;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .title {
  font-size: 2em;
}

.header a {
  padding-left: 20px;
  text-decoration: none;
  color: white;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  font-size: .7em;
  padding: 20px 0;
}