@import "~bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  height: 100%;
  background-color: #282c34;
  color: white;
  /* font-family: Cambria, Georgia, "Times New Roman", Times, serif; */
  /* font-family: roboto; */
  font-family: 'Droid Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}